import {cardCls} from "../../styles";
import {useGetAdminStatisticsQuery, useGetUsersQuery} from "../../api/generatedApi";
import {ErrorComponent} from "../../components/util/ErrorComponent";
import {LoadingView} from "../ErrorView";
import React from "react";

export function AdminStatistics() {

    const {data, error, refetch} = useGetAdminStatisticsQuery()
    if (error) {
        return <ErrorComponent error={error}/>
    }
    if (data === undefined) {
        return <LoadingView description={""}/>
    }

    let tableHeaderClasses = "p-4 border-b-2 border-blue-200 dark:border-slate-800 bg-blue-100 dark:bg-slate-700 " +
        "text-left text-xs font-semibold text-gray-600 dark:text-gray-200 uppercase tracking-wider";
    let tableDataClasses = "p-3 border-b border-gray-200 dark:border-gray-500 bg-white dark:bg-slate-600 text-sm";

    let dbSizeGB = Math.round(data.databaseSize / (1024 * 1024) * 100) / 100;
    let filestoreSizeGB = Math.round(data.filestoreSize / (1024 * 1024 * 1024) * 100) / 100;

    return (
        <>
            <h2 className="flex justify-center p-3 text-xl">
                Statistics
            </h2>

            <div className={`${cardCls} container m-auto mb-5 w-1/2`}>
                <table className="w-full">
                    <thead>
                    <tr>
                        <th className={tableHeaderClasses}>Type</th>
                        <th className={tableHeaderClasses}>Value</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className={tableDataClasses}>Users</td>
                        <td className={tableDataClasses}>{data.userCount}</td>
                    </tr>
                    <tr>
                        <td className={tableDataClasses}>Audiobooks</td>
                        <td className={tableDataClasses}>{data.audiobookCount}</td>
                    </tr>
                    <tr>
                        <td className={tableDataClasses}>Series</td>
                        <td className={tableDataClasses}>{data.seriesCount}</td>
                    </tr>
                    <tr>
                        <td className={tableDataClasses}>Authors</td>
                        <td className={tableDataClasses}>{data.authorCount}</td>
                    </tr>
                    <tr>
                        <td className={tableDataClasses}>Narrators</td>
                        <td className={tableDataClasses}>{data.narratorCount}</td>
                    </tr>
                    <tr>
                        <td className={tableDataClasses}>Database Size</td>
                        <td className={tableDataClasses}>{dbSizeGB} MB</td>
                    </tr>
                    <tr>
                        <td className={tableDataClasses}>Filestore Size</td>
                        <td className={tableDataClasses}>{filestoreSizeGB} GB</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}